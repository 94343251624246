import React from "react";
import {DataGrid, GridColDef, GridRowSelectionModel, useGridApiRef} from "@mui/x-data-grid";
import ICNTableFooter from "../subcomponents/Footer/ICNTableFooter";
import {GridSortModel} from "@mui/x-data-grid/models/gridSortModel";

interface LMTableProps {
  rows: any[]
  columns: GridColDef[]
  options: TableOptions
  selectionModel: GridRowSelectionModel
  selectionHandler: (rowSelectionModel: GridRowSelectionModel) => void
}

export interface TableOptions {
  sortModel?: GridSortModel,
  summaryFooter?: boolean
}

export default function LMTable(props: LMTableProps) {
  // The ref below is used to get the current scrolling of the data grid.
  const dataGridRef = React.useRef<HTMLDivElement>(null);

  // This apiRef is used to get the state of modifiable objects inside the data grid (e.g. the column widths)
  const apiRef = useGridApiRef();

  const getDisplayedColumns = () => {
    return apiRef.current.getAllColumns();
  };

  return (
    <div style={{
      width: '100%',
    }}>
      <DataGrid
        ref={dataGridRef}
        apiRef={apiRef}
        rows={props.rows}
        columns={props.columns}
        getRowId={(row) => row._id}
        initialState={{
          pagination: {
            paginationModel: {page: 0, pageSize: 15},
          },
          ...(props.options.sortModel ? {
            sorting: {
              sortModel: props.options.sortModel
            }
          } : {}),
        }}
        pageSizeOptions={[15, 30, 50]}
        checkboxSelection
        rowSelectionModel={props.selectionModel}
        onRowSelectionModelChange={props.selectionHandler}
        slots={props.options.summaryFooter ? {
          footer: () => (
            <ICNTableFooter
              columns={getDisplayedColumns()}
              tableData={props.rows}
              dataGridRef={dataGridRef}
            />
          ),
        } : {}}
      />
    </div>
  )
}
